import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FaGooglePlay } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";

function ProjectCards(props) {
  // Replace \n with <br /> in the description
  const formattedDescription = props.description.split('\n').map((item, key) => {
    return <React.Fragment key={key}>{item}<br/></React.Fragment>;
  });

  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {formattedDescription}
        </Card.Text>

        {/* Render GitHub button only if ghLink is provided */}
        {props.ghLink && (
          <Button variant="primary" href={props.ghLink} target="_blank" style={{ marginRight: "10px" }}>
            <BsGithub /> &nbsp;
            {props.isBlog ? "Blog" : "GitHub"}
          </Button>
        )}

        {/* Render Demo button if it's not a Blog and demoLink is provided */}
        {!props.isBlog && props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            style={{ marginRight: "10px" }}
          >
            <CgWebsite /> &nbsp;
            {"Demo"}
          </Button>
        )}

        {/* Render Google Play Store button if playStoreLink is provided */}
        {props.playStoreLink && (
          <Button
            variant="primary"
            href={props.playStoreLink}
            target="_blank"
            style={{ marginRight: "10px" }}
          >
            <FaGooglePlay /> &nbsp;
            {"Google Play"}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}

export default ProjectCards;
