import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Software Developer",
          "Full Stack Developer",
          "PHP Full Stack Developer",
          "Mobile App Developer",
          "React Native Developer",
          ".NET/C# Developer",
          "JavaScript Developer",
          "Full Stack Engineer"
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 6
      }}
    />
  );
}

export default Type;
