import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import blackJack from "../../Assets/Projects/blackjack.JPG";
import php from "../../Assets/Projects/php.JPG";
import ntier from "../../Assets/Projects/ntier.JPG";
import node from "../../Assets/Projects/node.JPG";
import finalProject from "../../Assets/Projects/finalproject.JPG";
import movieTracker from "../../Assets/Projects/movietracker.JPG";
import bibleTrivia from "../../Assets/bibletrivia.webp";
import movieTrackerMobile from "../../Assets/movietracker.webp";
import earnaroo from "../../Assets/earn.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Recent <strong className="purple">Work </strong>
        </h1>
        <p style={{ color: "white" }}>
          A couple projects I have been working on lately.
        </p>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={movieTrackerMobile}
              isBlog={false}
              title="Movie Tracker"
              description="CinemBuddy Movie Tracker stands as a testament to my expertise in mobile app development, leveraging cutting-edge technologies and best practices to deliver an immersive cinematic experience. Developed using React Native, Redux, and SQLite, this app showcases my proficiency in state management, API integration, and database management.

Key Technical Features:

React Native: Employed for cross-platform development, ensuring a seamless experience for both iOS and Android users.

Redux: Implemented for efficient state management, enabling smooth data flow and enhanced user interactions.

SQLite Database: Utilized for robust local storage, enabling users to track their watchlists and movie details offline.

API Integration: Integrated APIs to fetch movie data, including details, reviews, and ratings, providing users with comprehensive information on their favorite films.

User Stats Tracking: Implemented user stat tracking functionality to record and analyze movie-watching statistics, offering valuable insights into user preferences and behaviors.

Intuitive Design: Designed with a user-centric approach, ensuring a visually appealing and intuitive interface that enhances the overall user experience.

List Management: Developed custom list management features, allowing users to categorize and organize their movie collections effortlessly.

Achievements and Badges: Implemented achievement and badge systems to gamify the movie-watching experience, rewarding users for their engagement and milestones achieved.

Rate and Review: Enabled users to share their thoughts and ratings for movies, fostering community engagement and interaction within the app.

CinemBuddy Movie Tracker is more than just a mobile app; it's a showcase of my skills and expertise in mobile app development, demonstrating my ability to create feature-rich and user-friendly applications that stand out in the competitive app market."
              playStoreLink="https://play.google.com/store/apps/details?id=com.ncp889.MovieTracker"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bibleTrivia}
              isBlog={false}
              title="Bible Trivia"
              description="
              The Bible Trivia Game, developed with React Native, combines educational and entertaining elements to help users deepen their knowledge of the King James Bible.

Key Features:
Complete Bible Access: Navigate through the Old and New Testaments.
Extensive Trivia Database: 660 levels and 4800 questions.
Props System: Inspired by the Who Wants to be a Millionaire game, with abilities like freezing time and removing two incorrect choices.
Offline Leaderboard: Track weekly scores without an internet connection.
Animations: Particle animations and confetti for visual appeal.
Theme Picker: Three customizable themes.
Notepad Section: Track mistakes or favorite questions, review answers, and navigate to related Bible verses.
Daily Verse: API-driven daily inspiration.
Progress Tracking: Track levels and stages.
Gem System: Spend gems on props and daily rewards.
AdMob Monetization: Balanced revenue generation.
Upcoming In-App Purchases: Store with in-app purchases.

Development and Technology:
React Native: Cross-platform development.
PHP Tool: For data management and building SQLite database.
SQLite Database: Efficient local storage.
API Integration: Dynamic content like daily verses.
AdMob Integration: For monetization.
340+ Hours of Development: From design to implementation.

The Bible Trivia Game is a testament to my skills in mobile app development, database management, and user-centric design. It stands out in the crowded app market, showcasing my dedication to creating engaging digital experiences.
"
              playStoreLink="https://play.google.com/store/apps/details?id=com.ncp889.BibleTrivia"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={earnaroo}
              isBlog={false}
              title="Earnaroo"
              description="Introducing Earnaroo, a feature-rich Android Studio app meticulously crafted using Android Studio, built upon robust object-oriented programming (OOP) principles. Earnaroo seamlessly integrates with various APIs, SDKs, and third-party providers to offer users a rewarding experience.

Key Features:

Earn Coins: Users can effortlessly earn coins by engaging in various activities such as completing surveys, completing tasks, watching videos, and referring friends.

Redeem Rewards: With the accumulated coins, users can redeem them for Amazon or PayPal gift cards, providing them with tangible rewards for their efforts.

AdMob Integration: Monetize the app with AdMob integration, allowing for targeted advertising and revenue generation.

Admin Tool: Built with React and PHP, the admin tool provides seamless management of survey and task wallets, ensuring efficient management of user earnings from third-party providers.

Firebase Authentication: Utilizes Firebase for robust user authentication and secure data management within the app, ensuring user privacy and data security.

Engagement Features: Keep users engaged with daily spins, daily check-ins for bonus coins, and a wheel spin with varying coin amounts, enhancing user retention and satisfaction.

Earnaroo is more than just a mobile app; it's a testament to my expertise in Android app development, showcasing my proficiency in leveraging APIs, SDKs, and server-side technologies to create a rewarding and engaging user experience. With Earnaroo, users can earn rewards effortlessly while enjoying a seamless and intuitive app experience.

This project demonstrates my ability to design, develop, and deploy complex mobile applications that meet the needs of both users and administrators, solidifying my position as a skilled and versatile mobile app developer."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={finalProject}
              isBlog={false}
              title="HR Management System"
              description="The HR and Purchase Management System stands as the pinnacle of my academic journey, representing a culmination of two years of rigorous coursework and practical application. This ambitious capstone project, undertaken with a dedicated team member, pushed the boundaries of our knowledge and skills across various technologies and methodologies.

Key Highlights:

Scope and Scale: Spanning four intense weeks of development, this project demanded unwavering dedication, with workdays stretching to 10-14 hours on average. The system's expansive nature required meticulous attention to detail and comprehensive planning to execute successfully.

Dual Focus on HR and Purchase Systems: Splitting responsibilities with a team member, I spearheaded the development of the HR system while my counterpart managed the Purchase system. This collaborative effort ensured holistic coverage of critical business functions, with each system tailored to meet specific operational needs.

Technology Stack: Leveraging a diverse array of technologies, the project utilized C# with Windows Forms for the desktop application, Angular for the frontend web interface, and Android Studio for mobile app development with Kotlin. The backend was powered by .NET Framework, following an N-tier architecture, and integrated with Microsoft SQL Server for data storage and retrieval.

Comprehensive Functionality: The HR system encompassed a wide range of features, including employee and department management, performance reviews, and search functionality. Each feature adhered to strict business rules, with concurrency controls in place to maintain data integrity.

Approval-Driven Development: Following an agile methodology, each sprint required approval from project leaders based on UML and ERD designs before progressing to coding. This iterative approach ensured alignment with project objectives and facilitated seamless integration of frontend and backend components.

Full CRUD Operations: The web application offered a comprehensive CRUD (Create, Read, Update, Delete) interface, enabling users to perform essential operations seamlessly. Additionally, the mobile app, developed using Kotlin, incorporated lessons from the Android Development course, providing a rich user experience tailored for on-the-go access.

This project exemplifies my ability to tackle complex challenges, leverage cutting-edge technologies, and collaborate effectively within a team environment to deliver impactful solutions."

              demoLink="https://www.youtube.com/watch?v=e8IoHResgzk"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={movieTracker}
              isBlog={false}
              title="Movie Tracker"
              description="As part of a collaborative team effort, I contributed to the development of the Movie Tracker project, where each team member focused on a specific aspect of the application. My primary responsibility revolved around frontend development using Angular, leveraging my expertise in JavaScript and Angular concepts acquired through dedicated coursework.

Key Highlights:

Angular Frontend Development: Spearheaded frontend development using Angular, crafting user-friendly interfaces and implementing essential components to enhance the user experience.

CRUD Functionality Implementation: Integrated CRUD (Create, Read, Update, Delete) functionality seamlessly into the frontend, utilizing RESTful API endpoints provided by another team member who worked on the backend with Spring Boot.

Angular Basics Mastery: Leveraged foundational Angular concepts acquired through dedicated coursework, including component architecture, data binding, and routing, to build robust frontend functionalities.

Collaborative Teamwork: Engaged in collaborative teamwork, ensuring seamless integration of frontend and backend components to deliver a cohesive and functional application.

This project provided valuable hands-on experience in full-stack development, allowing me to apply my skills in frontend development with Angular and collaborate effectively within a team environment to deliver a successful project."
              ghLink=""
              demoLink="https://www.youtube.com/watch?v=AhqUhkPM-Nc"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ntier}
              isBlog={false}
              title="N-TIER Project - Hotel Booking"
              description="The N-TIER Project focused on building a sophisticated Hotel Reservation System, serving as the culmination of our coursework. This comprehensive project required meticulous planning and implementation of advanced architectural concepts to create a scalable and efficient application.

Key Components:

Database Design and Implementation: Led the design and implementation of the database structure, incorporating Entity-Relationship Diagrams (ERD) and Unified Modeling Language (UML) to ensure efficient data management.

N-TIER Design Methodology: Applied the N-TIER design methodology to architect a robust and scalable application, separating the system into multiple layers to enhance maintainability and scalability.

Business Rules Implementation: Implemented business rules to enforce logic and constraints within the system, ensuring data integrity and reliability.

Stored Procedures Development: Developed stored procedures in Microsoft SQL Server to optimize database operations and enhance performance.

Microsoft SQL Integration: Integrated Microsoft SQL Server as the backend database, leveraging its powerful features for efficient data storage and retrieval.

This project challenged me to apply theoretical knowledge into practice, honing my skills in database design, architectural design, and SQL development. By successfully delivering a functional Hotel Reservation System, I demonstrated proficiency in implementing complex systems while adhering to industry best practices."
              ghLink=""
              demoLink="https://www.youtube.com/watch?v=vACswtsvhuw"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={node}
              isBlog={false}
              title="Node with Express Weather API"
              description="In this advanced JavaScript course during term 4, I expanded upon the foundational knowledge acquired in previous JavaScript courses, particularly focusing on leveraging NodeJS to develop applications for web and mobile platforms. Combining this with responsive web design principles, I delved into a wide range of competencies essential for modern web development.

Key Achievements:

Advanced JavaScript Proficiency: Built upon the fundamentals of JavaScript, mastering advanced concepts and techniques vital for server-side development.

Server-Side Middleware Creation: Developed server-side middleware using JavaScript, enhancing the functionality and responsiveness of web applications.

Framework Utilization: Leveraged frameworks and libraries to streamline development processes, creating robust backend systems tailored for diverse application needs.

DOM Manipulation and Web Services: Demonstrated expertise in DOM manipulation and web services integration, enabling dynamic and interactive user experiences.

Express Framework Mastery: Employed the Express framework to build scalable and efficient web applications, facilitating seamless interaction with third-party APIs like the Weather API and Twilio SendGrid.

MongoDB Integration: Utilized MongoDB for data storage and retrieval, implementing robust database solutions for enhanced application functionality.

Real-World Application Development: Engaged in hands-on application development, producing a feature-rich application with functionalities such as user weather reports and a contact page for message submissions, integrating third-party APIs seamlessly.

This project exemplifies my proficiency in NodeJS, Express, and MongoDB, showcasing my ability to create dynamic, data-driven web applications that deliver exceptional user experiences."
              demoLink="https://shielded-wildwood-02502.herokuapp.com/weather"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={blackJack}
              isBlog={false}
              title="Blackjack - OOP"
              description="As part of my Object-Oriented Programming (OOP) course in C#, I developed a Blackjack game to apply the concepts learned throughout the term. This project provided hands-on experience with essential OOP principles, including interfaces, inheritance, polymorphism, encapsulation, abstraction, and aggregation.

Key Features:

Game Development: Designed and implemented a fully functional Blackjack game, showcasing proficiency in OOP methodology and program architecture.

User Interface: Created an intuitive user interface using Windows Forms, allowing players to deal cards, view their hand, and make strategic decisions like standing or hitting.

Card Handling: Implemented card dealing functionality to simulate gameplay, providing an interactive experience for players to enjoy.

This project not only demonstrated my understanding of OOP concepts but also highlighted my ability to apply them in practical scenarios. It served as a testament to my skills in C# development and game design, showcasing my passion for creating engaging and interactive software applications."
              demoLink="https://www.youtube.com/watch?v=l3yeSxdZ3WQ"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={php}
              isBlog={false}
              title="PHP Portfolio Project With CMS"
              description="My PHP portfolio project featuring a robust Content Management System (CMS), developed during my college PHP course. Throughout the course, I gained expertise in PHP development, mastering essential concepts such as setting up developer environments, syntax fundamentals, and advanced techniques like object-oriented programming (OOP) and database management.

Key Learnings:

Developer Environment Setup: Configured efficient developer environments for PHP development, ensuring seamless workflow and project management.

Syntax Essentials: Mastered PHP syntax and language essentials, including data types, form handling, and file management, enabling smooth interaction with user input.

Database Management: Delved into SQL and database management, learning techniques for efficient data manipulation, pagination, and search functionality.

Object-Oriented Programming: Utilized OOP principles to design scalable and maintainable PHP applications, enhancing code reusability and readability.

Security Practices: Implemented robust security practices, including user authentication, session management, and data encryption, ensuring data integrity and user privacy.

CMS Development: Designed and developed a fully functional CMS, empowering users to manage content effortlessly, from creating and editing pages to organizing content hierarchies.

Project Planning and Design: Engaged in comprehensive project planning and design phases, including creating ERDs, UML diagrams, wireframes, and color schemes, to ensure a cohesive and visually appealing user experience.

This individual project allowed me to showcase my PHP skills and creativity, from planning and designing the website to implementing advanced features like user authentication and dynamic content management. Overall, it was a rewarding and enriching experience, highlighting my passion for PHP development and web application design."
              demoLink="https://www.youtube.com/watch?v=QxlZHgOep4E"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
