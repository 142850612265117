import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi there, I am <span className="purple">Nick Pawsey </span>
            living <span className="purple"> in Shediac, Canada.</span>
            <br />I am a junior full stack developer seeking a full time position with the right company.
            <br />
            <br />
            Outside of coding, I have diverse interests:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Guitar
            </li>
            <li className="about-activity">
              <ImPointRight /> FPV Drones
            </li>
            <li className="about-activity">
              <ImPointRight /> Gaming
            </li>
            <li className="about-activity">
              <ImPointRight /> Horror Movies
            </li>
          </ul>

          <p style={{ color: "yellow"}}>
  "Success favours the brave, for opportunities seldom knock twice." - Unknown
</p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
